<template>
  <div>
    <audio id="myAudio">
      <source :src="audioLink" type="audio/mpeg" />
    </audio>
    <vx-card actionable class="cardx">
      <vs-row style="margin-bottom: 1%" >
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="1"><h4>Call Logs</h4></vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="2"><h4>Count  : {{this.total_count}}</h4></vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="9"></vs-col>
      </vs-row>
      <vs-row style="margin-bottom: 1%">
        <vs-col vs-w="2.3" vs-type="flex" vs-justify="flex-start" vs-align="center" >
          <vs-radio v-model="selectedSpocRadio" vs-name="selectedSpocRadio" vs-value="CM" v-if="login_user_subteam != 'SR'"><b style="color:#7367f0;">CM</b></vs-radio>&nbsp;&nbsp;
          <vs-radio v-model="selectedSpocRadio" vs-name="selectedSpocRadio" vs-value="SR" v-if="login_user_subteam != 'CM'"><b style="color:#7367f0;">SR</b></vs-radio>&nbsp;&nbsp;
          <vs-radio v-model="selectedSpocRadio" vs-name="selectedSpocRadio" vs-value="GM" v-if="login_user_subteam != 'CM' && login_user_subteam != 'SR'"><b style="color:#7367f0;">GM</b></vs-radio>
          <!-- <vs-radio v-model="selectedSpocRadio" vs-name="selectedSpocRadio" vs-value="HR"><b style="color:#7367f0;">HR</b></vs-radio> -->
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <treeselect
            v-model="selectedSpoc"
            :multiple="true"
            :options="treeDataBDE"
            style="width: 240px; margin-right: 3%"
          />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1.5">
          <!-- <vs-dropdown vs-custom-content vs-trigger-click>
            <a class="a-icon" href.prevent @click="showTreeBox">
              Levels
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="dropdown-login customDropDown">
              <treeselect
                v-model="initSelectedLevels"
                :multiple="true"
                :options="treeDataLevels"
              />
            </vs-dropdown-menu>
          </vs-dropdown> -->
          <treeselect
            v-model="initSelectedLevels"
            :multiple="true"
            :options="treeDataLevels"
            placeholder = "M Levels"
            style="width: 220px; margin-right: 3%"
          />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1.5">
          <treeselect
            v-model="initSelectedULevels"
            :multiple="true"
            :options="treeDataULevels"
            placeholder = "U Levels"
            style="width: 220px; margin-right: 3%"
          />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <div style="display:flex; justify-content: center; align-items: center;">
            <date-range-picker
              ref="picker"
              :opens="'center'"
              :locale-data="{
                firstDay: 1,
                format: 'DD-MMM-YYYY',
                customRangeLabel: 'Custom Range',
              }"
              :minDate="null"
              :maxDate="getmaxDate()"
              :singleDatePicker="false"
              :timePicker="false"
              :timePicker24Hour="true"
              :showWeekNumbers="false"
              :showDropdowns="false"
              :autoApply="false"
              v-model="date"
              style="width: 215px; margin-right: 3%"
              @update="analizePagination"
            ></date-range-picker>
            <!-- <datepicker id="datepicker" class="datepicker" placeholder="Select Date" v-model="date"></datepicker> -->
            <vs-button
          style="margin-left: 1%;"
            radius
            color="dark"
            type="gradient"
            icon="cancel"
            @click="getEmptyCallDates()"></vs-button>
          </div>

        </vs-col>
        <!-- <vs-col vs-type="flex" vs-justify="right" vs-align="right" vs-w="1">Audit Status:</vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="2">
          <vs-select
            v-model="selected_audit_status"
            >
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in audit_status_options" />
            </vs-select>
        </vs-col> -->
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1.5">
          <vs-dropdown class="gap" vs-custom-content vs-trigger-click :key="dropdownKey">
            <vs-button
              radius
              color="dark"
              type="gradient"
              icon="search"
            ></vs-button>
            <vs-dropdown-menu class="loginx">
              <vs-row vs-w="12" class="searchGap">
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="9"
                >
                  <vs-input
                    type="text"
                    label-placeholder="Candidate ID"
                    v-model="candidate_id"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analizePagination"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" class="searchGap">
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="9"
                >
                  <vs-input
                    type="text"
                    label-placeholder="Person name"
                    v-model="person_name"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analizePagination"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" class="searchGap" style="margin-bottom: 5%">
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="9"
                >
                  <vs-input
                    type="text"
                    label-placeholder="Phone Number"
                    v-model="phone_number"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analizePagination"
                  ></vs-button>
                </vs-col>
              </vs-row>
            </vs-dropdown-menu>
          </vs-dropdown>

          <vs-button
            radius
            class="gap"
            color="dark"
            type="gradient"
            icon="refresh"
            @click="refreshData"
          ></vs-button
        ></vs-col>
      </vs-row>
      <vs-row style="margin-bottom: 1%;">
        <!-- <vs-col vs-w="1">Audit Filters - </vs-col> -->
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="1">Audit Status:</vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="2">
          <vs-select
            v-model="selected_audit_status"
            >
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in audit_status_options" />
            </vs-select>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="1">Audit Done By:</vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="2">
          <vs-select
            width="100%"
            v-model="selected_audit_done_spoc"
            multiple
            >
            <vs-select-item :key="index" :value="item" :text="item" v-for="(item,index) in audit_done_spocs" />
            </vs-select>
            <!-- <v-select
              placeholder="Select Spoc"
              class="w-full"
              :multiple="true"
              v-model="selected_audit_done_spoc"
              :options="audit_done_spocs"
            >
            </v-select> -->
        </vs-col>
        <vs-col vs-type="flex"  vs-justify="flex-start" vs-align="center" vs-w="4">
          <div>
            <date-range-picker
              ref="picker"
              :opens="'center'"
              :locale-data="{
                firstDay: 1,
                format: 'DD-MMM-YYYY',
                customRangeLabel: 'Custom Range',
              }"
              :minDate="null"
              :maxDate="getmaxDate()"
              :singleDatePicker="false"
              :timePicker="false"
              :timePicker24Hour="true"
              :showWeekNumbers="false"
              :showDropdowns="false"
              :autoApply="false"
              v-model="audit_date"
              style="width: 240px; margin-right: 3%"
              @update="analizePagination"
            ></date-range-picker>
            <!-- <datepicker id="datepicker" class="datepicker" placeholder="Select Date" v-model="date"></datepicker> -->
          </div>
           <vs-button
              style="margin-left: 1%;"
              radius
              color="dark"
              type="gradient"
              icon="cancel"
              @click="getEmptyAuditDates()"></vs-button>
        </vs-col>
        <vs-col vs-w="1">
           <vs-button
            radius
            class="gap"
            color="dark"
            type="gradient"
            @click="analizePagination"
          >Go</vs-button
        >
        </vs-col>
      </vs-row>
      <vs-table max-items="10" :data="users">
        <template slot="thead">
          <!-- <vs-th>Person Id</vs-th> -->
          <vs-th>Can-ID</vs-th>
          <vs-th>Name</vs-th>
          <vs-th>Caller Spoc</vs-th>
          <vs-th>Time</vs-th>
          <!-- <vs-th>Contact Type</vs-th> -->
          <vs-th>Directory</vs-th>
          <vs-th>Call Duration</vs-th>
          <vs-th>M Levels</vs-th>
          <vs-th>U Levels</vs-th>
          <!-- <vs-th>Person Name</vs-th> -->
          <!-- <vs-th>Phone Number</vs-th> -->
          <vs-th>Audit Status</vs-th>
          <vs-th>Playback</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <!-- <vs-td>{{tr.person_id}}</vs-td> -->
            <vs-td>{{ tr.can_id }}</vs-td>

            <vs-td>{{ tr.person_name }}</vs-td>

            <vs-td>{{ getSpocName(tr.user_id) }}</vs-td>

            <vs-td>{{ getMomentDateFromTimestamp(tr.time) }}</vs-td>

            <!-- <vs-td>{{ tr.contact_type }}</vs-td> -->

            <vs-td>{{ tr.directory }}</vs-td>

            <vs-td>{{ timeConvert(tr.call_duration) }}</vs-td>

            <vs-td>{{ tr.mwb_level }}</vs-td>
            <vs-td>{{ tr.iiml_level }}</vs-td>
            <!-- <vs-td>{{ tr.person_name }}</vs-td> -->

            <!-- <vs-td>{{ tr.phone_number }}</vs-td> -->
            <vs-td v-if="tr.audit_done == 1 || tr.id == call_id">Yes</vs-td>
            <vs-td v-if="tr.audit_done == null && tr.id != call_id">No</vs-td>
            <vs-td>
              <vs-row>
                <div @click="openBigPopupFunction(tr)">
                  <vs-icon
                    class="clickableIcon"
                    icon="info_outline"
                    size="small"
                    color="dark"
                  ></vs-icon>
                </div>
                <div
                  v-if="tr.call_recording_found !== 0"
                  @click="fetchRecording(tr)"
                >
                  <vs-icon
                    class="clickableIcon"
                    icon="play_arrow"
                    size="small"
                    color="dark"
                  ></vs-icon>
                </div>
              </vs-row>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-row style="margin-top: 1%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="12"
        >
          <vs-pagination
            :total="last_page"
            v-model="selected_link"
          ></vs-pagination>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import axios from "axios";
import vSelect from "vue-select";
import constants from "../../constants.json";
import Datepicker from "vuejs-datepicker";
import EventBus from "../components/eventbus.js";
import DateRangePicker from "vue2-daterange-picker";
import Treeselect from "@riophae/vue-treeselect";
import moment from "moment";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { VTree, VSelectTree } from "vue-tree-halower";
import "vue-select/dist/vue-select.css";
import "vue-tree-halower/dist/halower-tree.min.css";
export default {
  data() {
    return {
      dropdownKey :0,
      call_id: null,
      total_count: "",
      gm_audit_spocs_data: [],
      selected_audit_done_spoc: [],
      audit_done_spocs: [],
      call_audit_done_users: [],
      call_audit_pending_users: [],
      selected_audit_status: "all",
      audit_status_options: [{text: "All", value: "all"}, {text: "Yes", value: "yes"}, {text: "No", value: "no"}],
      selectedSpocRadio:"",
      audioLink:
        "https://seet-miles-cm-call-recordings.s3.ap-south-1.amazonaws.com/38_1563349034000.mp3",
      date: {
        startDate: "",
        endDate: "",
      },
      audit_date: {
        startDate: "",
        endDate: "",
      },
      person_name: "",
      candidate_id: "",
      phone_number: "",
      selected_link: 1,
      pause: false,
      last_page: 0,
      audio: "",
      selectedSpoc: [],
      loadSpoc: [],
      spocList: [],
      treeDataBDE: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      users: [],
      fromUtil: false,
      searchIn: {},
      rawBde: "",
      rawcities: [],
      initSelectedLevels: [],
      initSelectedULevels: [],
      login_spoc_id:"",
      login_user_subteam: "",
      logged_in_team: '',
      treeDataULevels:[
        {
          id:"All",
          label:"All",
          children: [
            { id: "U0--", label: "U0--" },
            { id: "U0", label: "U0" },
            { id: "U1", label: "U1" },
            { id: "U1+", label: "U1+" },
            { id: "U2", label: "U2" },
            { id: "U3-", label: "U3-" },
            { id: "U3", label: "U3" },
            { id: "U3+", label: "U3+" },
            { id: "U3++", label: "U3++"},
            { id: "U4", label: "U4" },
            { id: "U5", label: "U5" },
            { id: "U6", label: "U6" },
            { id: "U7-", label: "U7-" },
            { id: "U7", label: "U7" },
            { id: "U7R", label: "U7R" },
            { id: "U7+", label: "U7+" },
            { id: "U8", label: "U8" },
            { id: "U8+", label: "U8+" },
            { id: "U9-", label: "U9-" },
            { id: "U9", label: "U9" },
            { id: "U9+", label: "U9+" },
            { id: "U10-", label: "U10-" },
            { id: "U10", label: "U10" },
            { id: "U11", label: "U11" },
            { id: "U11+", label: "U11+" },
            { id: "U12", label: "U12" },
          ]
        }
      ],
      treeDataLevels: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "P1",
              label: "P1",
              children: [
                {
                  id: "M3",
                  label: "M3",
                },
                {
                  id: "M3+",
                  label: "M3+",
                },
                {
                  id: "M3++",
                  label: "M3++",
                },
                {
                  id: "M5",
                  label: "M5",
                },
                {
                  id: "M6",
                  label: "M6",
                },
                {
                  id: "M6 AR",
                  label: "M6 AR",
                },
                {
                  id: "M6 AR-",
                  label: "M6 AR-",
                },
              ],
            },
            {
              id: "P2",
              label: "P2",
              children: [
                {
                  id: "M4",
                  label: "M4",
                },
                {
                  id: "M2",
                  label: "M2",
                },
                // {
                //   id: "L2",
                //   label: "L2",
                // },
                // {
                //   id: "L3",
                //   label: "L3",
                // },
                // {
                //   id: "L4",
                //   label: "L4",
                // },
                // {
                //   id: "L6",
                //   label: "L6",
                // },
                // {
                //   id: "L5",
                //   label: "L5",
                // },
                // {
                //   id: "L3+",
                //   label: "L3+",
                // },
              ],
            },
            {
              id: "P3",
              label: "P3",
              children: [
                {
                  id: "M4-",
                  label: "M4-",
                },
                {
                  id: "M1",
                  label: "M1",
                },
                // {
                //   id: "L4-",
                //   label: "L4-",
                // },
                // {
                //   id: "L1",
                //   label: "L1",
                // },
              ],
            },
            {
              id: "ENROLLED",
              label: "ENROLLED",
              children: [
                {
                    id: "M7",
                    label: "M7",
                },
                {
                    id: "M7-",
                    label: "M7-",
                },
                {
                    id: "M7+",
                    label: "M7+",
                },
                {
                    id: "M7#",
                    label: "M7#",
                },
                {
                    id: "M7-JJ",
                    label: "M7-JJ",
                },
                {
                    id: "M7X",
                    label: "M7X",
                },
                {
                    id: "M7D1",
                    label: "M7D1",
                },
                {
                    id: "M7D2",
                    label: "M7D2",
                },
                {
                    id: "M8#1",
                    label: "M8#1"
                },
                {
                    id: "M8#1-",
                    label: "M8#1-"
                },
                {
                    id: "M8#1+",
                    label: "M8#1+"
                },
                {
                    id: "M8#2",
                    label: "M8#2"
                },
                {
                    id: "M8",
                    label: "M8"
                },
                {
                    id: "M8-",
                    label: "M8-",
                },
                {
                    id: "M8+",
                    label: "M8+"
                },
                {
                    id: "M8+1",
                    label: "M8+1"
                },
                {
                    id: "M8+2",
                    label: "M8+2"
                },
                {
                    id: "M8+3",
                    label: "M8+3"
                },
                {
                    id: "M8+4",
                    label: "M8+4"
                },
                {
                    id: "M8+5",
                    label: "M8+5"
                },
                {
                    id: "M8+6",
                    label: "M8+6"
                },
                {
                    id: "M9",
                    label: "M9",
                },
                {
                    id: "M9+1",
                    label: "M9+1",
                },
                {
                    id: "M9+2",
                    label: "M9+2",
                },
                {
                    id: "M9+3",
                    label: "M9+3",
                },
                {
                    id: "M9-",
                    label: "M9-",
                },
                {
                    id: "M10",
                    label: "M10",
                },
                {
                    id: "M10#",
                    label: "M10#",
                },
                {
                    id: "M11",
                    label: "M11",
                },
                {
                    id: "M11#",
                    label: "M11#",
                },
                {
                    id: "M12",
                    label: "M12",
                },
                {
                    id: "M7-IR",
                    label: "M7-IR",
                },
                {
                    id: "M7-INT",
                    label: "M7-INT",
                }
              ]
            },
            {
              id: "Dnd",
              label: "Dnd",
              children:[
              {
              id: "N/A",
              label: "N/A",
            },
            {
              id: "DND",
              label: "DND",
            },
              ]
            }
          ],
        },
      ],
    };
  },
  components: {
    Datepicker,
    DateRangePicker,
    vSelect,
    Treeselect,
    VTree,
    VSelectTree,
    "v-select": vSelect,
    VuePerfectScrollbar,
  },
  watch: {
    selected_audit_done_spoc(){
    },
    selected_audit_status(val){
      if(val == "all"){
        this.selected_audit_done_spoc = [];
        this.audit_date.startDate = "";
        this.audit_date.endDate = "";
      }
      if(val == "no"){
        this.selected_audit_done_spoc = [];
        this.audit_date.startDate = "";
        this.audit_date.endDate = "";
        this.getEmptyAuditDates();
      }
      if(val == "yes"){
        this.audit_date.startDate = this.audit_date.startDate;
        this.audit_date.endDate = this.audit_date.endDate;
      }
    },
    selectedSpoc: function() {
      console.log(this.selectedSpoc, "selectedSpoc");
        this.analizePagination();
    },
    selected_link: function(val) {
      this.analizePagination(val);
    },
    initSelectedLevels: function () {
      this.analizePagination();
    },
    initSelectedULevels: function(){
      this.analizePagination();
    },
    selectedSpocRadio(val){
      this.person_name = "";
      this.candidate_id = "";
      this.phone_number = "";
      if(val === "CM"){
        this.getSpoctree()
      }
      if(val === "SR"){
        this.getSRSpocs()
      }
      if(val === "GM"){
        this.getGMSpocs()
      }
      if(val === "HR"){
        this.getHRSpocs()
      }
    }
  },
  mounted() {
    this.logged_in_team = localStorage.getItem("team")
    this.login_spoc_id = localStorage.getItem("spoc_id")
    this.login_user_subteam = localStorage.getItem("sub_team")
    if(this.logged_in_team == 'GM' && this.login_user_subteam == 'CM'){
      this.selectedSpocRadio = "CM"
    }else if(this.logged_in_team == 'GM' && this.login_user_subteam == 'SR'){
      this.selectedSpocRadio = "SR"
    }else{
      this.selectedSpocRadio = "CM"
    }
    EventBus.$on("update-audit", (call_id) => {
      this.call_id = call_id;
    });
    // this.cm2Redirect();
    // this.adminRedirect();
    // this.getSpoc();
    if(this.selectedSpocRadio === "CM"){
      this.getSpoctree()
    }
    if(this.selectedSpocRadio === "SR"){
      this.getSRSpocs()
    }
    // if(this.selectedSpocRadio === "GM"){
      this.getGMSpocs()
    // }
    if(this.selectedSpocRadio === "HR"){
      this.getHRSpocs()
    }
    this.date.startDate = new Date().setDate(new Date().getDate() - 1);
    this.date.endDate = new Date().setDate(new Date().getDate() - 1);
  },
  methods: {
    getEmptyAuditDates(){
      this.audit_date.startDate = "";
      this.audit_date.endDate = "";
    },
    getEmptyCallDates(){
      this.date.startDate = "";
      this.date.endDate = "";
    },
    showTreeBox() {
      return this.treeDataBDE;
    },
    openBigPopupFunction(tr) {
      console.log(tr);
      this.openBigPopup(tr.mwb_id);
    },
    refreshData() {
      this.person_name = "";
      this.candidate_id = "";
      this.phone_number = "";
      this.analizePagination();
    },
    getMomentDateFromTimestamp(value) {
      var dateString = moment.unix(value / 1000).format("DD-MMM-YYYY HH:mm");
      return dateString;
    },
    getmaxDate() {
      let newdate = new Date(new Date().setDate(new Date().getDate() - 1));
      // newdate = moment.unix(newdate / 1000).format("DD-MM-YYYY");
      // newdate = Date(newdate);
      // console.log(newdate);
      return newdate;
    },
    async buttonVisibility(data) {
      // return true;
      // let url = `${constants.ADMIN_SERVER_API}getSpocs`;
      let connected = false;
      let url = `https://seet-miles-cm-call-recordings.s3.ap-south-1.amazonaws.com/${
        data.user_id
      }_${data.time}.mp3`;
      try {
        let response = await axios({ url: url, method: "get" });
        if (response.status === 200) {
          console.log(response.status);
          connected = true;
        }
      } catch (err) {
        console.log(err);
        connected = false;
      }
      return connected;
      // const promise = new Promise((resolve, reject) => {
      //   axios
      //     .get(url)
      //     .then(response => {
      //       console.log("call log", response);
      //       resolve(true);
      //     })
      //     .catch(error => {
      //       console.log("got an error", error);
      //       reject(false);
      //     });
      // });
      // return promise;
      // axios
      //   .get(url)
      //   .then(response => {
      //     console.log(response);
      //     return true;
      //   })
      //   .catch(error => {
      //     return false;
      //   });
    },
    fetchRecording(call_log) {
      // console.log(call_log);
      this.$vs.loading();
      let obj = {
        call_log_id: call_log.id,
      };
      let url = `${constants.MILES_CRM_DEV}fetchPreSignedUrl`;
      axios
        .get(url, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("fetchRecording",response.data);
          if (response.data.data !== null) {
            this.playaudio(response.data.data, call_log);

          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    playaudio(audiofile, call_log) {
      // let audiofile = null;
      // if(data.extension == null){
      //   data.extension = "mp3";
      // }
      // else if(data.file_name != null){
      //   audiofile = `https://seet-miles-cm-call-recordings.s3.ap-south-1.amazonaws.com/${data.file_name}`;
      // }else{
      //   audiofile = `https://seet-miles-cm-call-recordings.s3.ap-south-1.amazonaws.com/${data.user_id}_${data.call_time}.${data.extension}`;
      // }
      this.audio = new Audio(audiofile);
      this.audio.onerror = function(error) {
        console.log(error);
        EventBus.$emit("error-finding-calllog");
      };
      EventBus.$emit("open-recording-popup", audiofile, call_log);
      EventBus.$emit("open-recording-popup-rawBde", this.rawBde);
      this.$vs.loading.close();
    },
    getSpocName(spoc_id) {
      let name = "";
      // console.log(this.rawBde);
      this.rawBde.forEach((spoc) => {
        if (spoc_id === spoc.id) {
          name = spoc.full_name;
        }
      });
      return name;
    },
    getSpoc() {
      let url = `${constants.ADMIN_SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // console.log(response.data);
          response.data.spocs.forEach((spoc) => {
            this.spocList.push(spoc);
          });
          if (this.$route.params.selected_obj === undefined) {
            this.selectedSpoc = response.data.spocs;
          } else {
            response.data.spocs.forEach((serverSpoc) => {
              if (serverSpoc.id === this.$route.params.selected_obj.spoc_id) {
                this.selectedSpoc.push(serverSpoc);
              }
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    analizePagination(val) {
      this.dropdownKey = Math.random()
      if (this.selected_link === 1) {
        this.generateTableData(1);
      } else {
        this.generateTableData(val);
      }
    },
    getSpoctree() {
      // let url = `${constants.ADMIN_SERVER_API}getSpocs`;
      let url = ''
      if (this.logged_in_team == 'GM' && this.login_user_subteam == 'CM') {
       url = `${constants.SERVER_API}getSpocsOfGM?gm_id=${this.login_spoc_id}`;
      }else{
       url = `${constants.SERVER_API}getSpocs`;
      }
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then(async (response) => {
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          this.Calling(response.data.spocs)

        })
        .catch((error) => {
          this.handleError(error);
        });
    },
     getSRSpocs() {
      // let url = `${constants.SERVER_API}getSRSpocs`;
      let url = ''
      if (this.logged_in_team == 'GM' && this.login_user_subteam == 'SR') {
       url = `${constants.SERVER_API}getSpocsOfGM?gm_id=${this.login_spoc_id}`;
      }else{
       url = `${constants.SERVER_API}getSRSpocs`;
      }
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getSRSpocs", response);
           this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          this.Calling(response.data.spocs)
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getGMSpocs() {
      let url = `${constants.SERVER_API}getGMSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getGMSpocs", response);
           this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          if(this.selectedSpocRadio === "GM"){
            this.Calling(response.data.spocs);
          }
          this.audit_done_spocs = [];
          for (let i = 0; i < response.data.spocs.length; i++) {
            const element = response.data.spocs[i];
            this.audit_done_spocs.push(element.full_name);
          }
          this.gm_audit_spocs_data = response.data.spocs;
          // this.selected_audit_done_spoc = response.data.spocs;
          console.log("gm spocs", response.data.spocs);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getHRSpocs(){
      let url = `${constants.SERVER_API}getHRTeamForUtilisation`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getHRSpocs", response);
           this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          this.Calling(response.data)
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    Calling(spocsData){
      console.log("Calling Spocsdata", spocsData);
      this.treeDataBDE[0].children = []
      this.selectedSpoc = []
      this.rawcities = []
      this.rawBde = []
       this.rawBde = spocsData;
          this.treeDataBDE[0].children = [];
          var unique = spocsData
            .map((name) => {
              return {
                count: 1,
                name: name.city,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.rawcities.push(key);
              var obj = {
                id: key,
                label: key,
                children: [],
              };
              this.treeDataBDE[0].children.push(obj);
            }
          }
         spocsData.forEach((server_spoc) => {
            this.treeDataBDE.forEach((bde) => {
              bde.children.forEach((bdechid) => {
                if (bdechid.label === server_spoc.city) {
                  // var obj = {
                  //   id: server_spoc.full_name,
                  //   label: server_spoc.full_name,
                  // };
                  // bdechid.children.push(obj);
                  if(this.login_spoc_id == 132 ||this.login_spoc_id == 937 || this.login_spoc_id == 39){
                      let obj = {
                      id: server_spoc.full_name,
                      label:server_spoc.full_name,
                    };
                    bdechid.children.push(obj);
                    }
                    else{
                      if(server_spoc.full_name.substring(0,1) != 'X'){
                        let obj = {
                          id: server_spoc.full_name,
                          label: server_spoc.full_name,
                        };
                        bdechid.children.push(obj);
                        }
                    }
                }
              });
            });
          });
          this.treeDataBDE[0].children = this.sortNestedArrayAlphabetically(
            this.treeDataBDE[0].children
          );
          if (this.rawBde.indexOf(this.searchIn) !== -1) {
            this.selectedSpoc.push(this.searchIn.name);
          } else {
            this.selectedSpoc.push("All");
          }
          // this.search_API(1);
          this.analizePagination();
    },
    datatoTimestamp(stringyDate) {
      var ms = "";
      if(stringyDate != ""){
        var dateyDate = new Date(this.timetoTimestamp(stringyDate));
         ms = dateyDate.valueOf();
      }
      if(stringyDate == ""){
         ms = "";
      }
      return ms;
    },
    generateTableData(page) {
      this.$vs.loading();
      let arra = [];
      let searchCriteria = [];
      let u_level_value = [];
      // this.selectedSpoc.forEach(selSpoc => {
      //   arra.push(selSpoc.id);
      // });
      this.selectedSpoc.forEach((sort) => {
        if (sort === "All") {
          this.treeDataBDE[0].children.forEach((child) => {
            child.children.forEach((child2) => {
              arra.push(child2.label);
            });
          });
        } else if (this.rawcities.indexOf(sort) !== -1) {
          this.treeDataBDE[0].children.forEach((child) => {
            if (child.label === sort) {
              child.children.forEach((child2) => {
                arra.push(child2.label);
              });
            }
          });
        } else {
          arra.push(sort);
        }
      });
     this.initSelectedULevels.forEach((sort)=>{
        if(sort === "All"){
          this.treeDataULevels[0].children.forEach((child) => {
              u_level_value.push(child.label);
          });
        }else {
          u_level_value.push(sort);
        }
     });
     console.log("find all value",u_level_value)
      this.initSelectedLevels.forEach((sort) => {

        if (sort === "All") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            console.log("sort", searchCriteria.push(child.label))
            searchCriteria.push(child.label);
          });
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            searchCriteria.push(child.label);
          });
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            searchCriteria.push(child.label);
          });
          this.treeDataLevels[0].children[3].children.forEach((child) => {
            searchCriteria.push(child.label);
          });
          this.treeDataLevels[0].children[4].children.forEach((child) => {
            searchCriteria.push(child.label);
          });
        }
        else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            searchCriteria.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            searchCriteria.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            searchCriteria.push(child.label);
          });
        }else if(sort === "ENROLLED"){
          this.treeDataLevels[0].children[3].children.forEach((child) => {
            searchCriteria.push(child.label);
          });
        }
        else if(sort === "Dnd"){
          this.treeDataLevels[0].children[4].children.forEach((child) => {
            searchCriteria.push(child.label);
          });
        }else {
          searchCriteria.push(sort);
        }
      });
      let newarr = [];
      let audit_newarr = [];
      let newsearchCriteria = [];
      if(this.selected_audit_done_spoc != []){
        this.selected_audit_done_spoc.forEach((name) => {
          this.gm_audit_spocs_data.forEach((spoc) => {
            if (name === spoc.full_name) {
              audit_newarr.push(spoc.id);
            }
          });
        });
      }
      arra.forEach((name) => {
        this.rawBde.forEach((spoc) => {
          if (name === spoc.full_name) {
            newarr.push(spoc.id);
          }
        });
      });
      searchCriteria.forEach((newlevel) => {

        // this.rawBde.forEach((spoc) => {
          if (newlevel === newlevel ) {
            newsearchCriteria.push(newlevel);
          }
        // });
      });
        let params = {
              audit_status: this.selected_audit_status,
              page: page,
              level: newsearchCriteria.join(),
              iiml_level:u_level_value.join(),
              spoc_ids: newarr.join(),
              from_date: this.datatoTimestamp(this.date.startDate),
              to_date: this.datatoTimestamp(this.date.endDate),
              audit_from_date: this.datatoTimestamp(this.audit_date.startDate),
              audit_to_date: this.datatoTimestamp(this.audit_date.endDate),
              audit_done_by: audit_newarr.join(),
              phone_number: this.phone_number,
              person_name: this.person_name,
              can_id: this.candidate_id,
            };
      // console.log(params);
      let url = "";
      if(this.selectedSpocRadio !== 'HR'){
        url = `${constants.ADMIN_SERVER_API}getAllCallLogsTest`;
      }
      if(this.selectedSpocRadio === 'HR'){
        url = `${constants.SERVER_API}getHRCallLogsForAudit`;
      }
      axios
        .get(url, {
          params: params,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getAllCallLogsTest",response);
          if (response.data.status !== "success") {
            this.handleNotification(response);
            this.$vs.loading.close();
            return;
          }
          response.data.data.data.forEach((each) => {
            each.pause = false;
          });
          this.users = response.data.data.data;
          this.total_count = response.data.data.total;
          // this.checkUsersAuditStatus(this.users);
          this.last_page = response.data.data.last_page;
          this.selected_link = response.data.data.current_page;
          // this.countsleads = response.data.onboarddata.total;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          // if (error.response) {
          //   // console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        });
    },
    // checkUsersAuditStatus(users){
    //   this.call_audit_done_users = [];
    //   this.call_audit_pending_users = [];
    //   for (let i = 0; i < users.length; i++) {
    //     const element = users[i];
    //     if(element.audit_done == 1){
    //       this.call_audit_done_users.push(element);
    //     }
    //     if(element.audit_done == null){
    //       this.call_audit_pending_users.push(element);
    //     }
    //   }
    //   if(this.selected_audit_status == null){
    //     this.users = this.call_audit_pending_users;
    //   }
    //   if(this.selected_audit_status == 1){
    //     this.users = this.call_audit_done_users;
    //   }
    // },
    timeConvert(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);
      return (
        ("0" + h).slice(-2) +
        ":" +
        ("0" + m).slice(-2) +
        ":" +
        ("0" + s).slice(-2)
      );
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
  },
};
</script>

<style>
#div-with-loading {
  width: 200px;
  height: 200px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
}
.vs-radar-text {
  color: darkgray;
  position: relative;
  bottom: 70px;
  left: 35px;
  font-size: larger;
}
.radial_quarter {
  position: relative;
  height: 260px;
}
.lineAreaChartSpline {
  position: relative;
  height: 260px;
}
.vs-radar-thisquarter {
  color: darkgray;
  position: relative;
  bottom: 70px;
  font-size: larger;
  text-align: center;
}
.input-search {
  position: relative;
  right: 10px;
}
.table_heading {
  left: 20px;
  position: relative;
}
.vs-table--tbody-table tr {
  /* background-color:#ffbc00; */
  font-family: sans-serif;
  font-size: smaller;
  /* border: 1px solid black; */
}
.datepicker .vdp-datepicker__calendar {
  z-index: 2000;
}
#spocselect {
  z-index: 2000;
}
.datepicker {
  position: relative;
  /* top: 10px; */
}
.clickableIcon {
  cursor: pointer;
}
</style>
